<template>
  <div>
    <div v-if="this.$route.name === 'Order'">
      <Order />
    </div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
import Order from "@/views/companyApp/order/Order.vue";

export default {
  name: 'OrderParent',
  components: {
    Order
  }
}
</script>
